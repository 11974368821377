import type { ParsedUrlQuery } from "querystring";

export default function getQueryStringValue(query: ParsedUrlQuery, key: string, defaultValue: string | null = null) {
  const value = query[key];
  if (!value) {
    return defaultValue;
  }
  if (typeof value === "string") {
    return value;
  }
  if (Array.isArray(value) && value.length > 0 && typeof value[0] === "string") {
    return value[0];
  }
  return defaultValue;
}
